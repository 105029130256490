import * as React from "react";
import { useEffect } from "react";
import { Redirect, RouteComponentProps } from "react-router-dom";
import { overview } from "../services/routes";
import { addEncodedCode } from "../utils/codeManager";

interface ICodeProps extends RouteComponentProps<{ code: string }> {}

const Code: React.FC<ICodeProps> = ({ match }) => {
  const code = match.params.code;
  useEffect(() => {
    addEncodedCode(code);
  }, [code]);
  return <Redirect to={overview()} />;
};

export default Code;
