import { useRef, useEffect, useState, useCallback } from "react";

export const useScrollIntoView = <T extends Element>(deps: any = []) => {
  const elementRef = useRef<T>(null);
  useEffect(() => {
    elementRef.current && elementRef.current.scrollIntoView(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementRef, ...deps]);
  return elementRef;
};

export const useModal = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return {
    open,
    handleOpen,
    handleClose,
  };
};
