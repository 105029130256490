export const BypassInterestAction = "BYPASS_INTEREST_ACTION";
export type CodeActions = typeof BypassInterestAction;
export type CodeObject = {
  action: CodeActions;
  expiration: Date;
  payload?: unknown;
};

export const encodeCodeObject = (codeObj: CodeObject): string => {
  const codeString = JSON.stringify(codeObj);
  return btoa(codeString);
};

export const decodeCode = (code: string): CodeObject | null => {
  try {
    const decodedString = atob(code);
    const { action, expiration, payload } = JSON.parse(decodedString);
    return {
      action,
      payload,
      expiration: new Date(expiration),
    };
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const isValidCode = (code: string): boolean => {
  const codeObj = decodeCode(code);
  return !!codeObj && !!codeObj.action && !!codeObj.expiration;
};

export const addEncodedCode = (code: string) => {
  if (!isValidCode(code)) return;
  const codes = readEncodedCodes();
  if (codes.includes(code)) return;
  saveEncodedCodes([...codes, code]);
};

export const saveEncodedCodes = (codes: string[]) => {
  try {
    localStorage.setItem("codes", JSON.stringify(codes));
  } catch (error) {
    console.error(error);
  }
};

export const readEncodedCodes = (): string[] => {
  try {
    const codes = localStorage.getItem("codes");
    return codes ? JSON.parse(codes) : [];
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const readCodes = (): CodeObject[] => {
  const codes = readEncodedCodes();
  return codes
    .map((code) => decodeCode(code))
    .filter(
      (codeObj): codeObj is CodeObject =>
        !!codeObj && codeObj.expiration > new Date()
    );
};
